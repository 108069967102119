import { Component, OnDestroy, OnInit } from "@angular/core";
import { ExtenderService } from "src/app/services/extender.service";
import { FormService } from "src/app/services/form.service";
import { PagingService } from "src/app/services/paging.service";
import { PriceService } from "src/app/services/price.service";
import { TrackingService } from "src/app/services/tracking.service";
import {
  SwaggerException,
  Client,
  PackageTagEnum,
  PackageRequest,
  PackageResponse,
  PackageTypeEnum,
  BusinessProposalStatusEnum,
  ErrorResponse
} from "src/domain/client";
import { PageBaseComponent } from "../../base/page-base/page-base.component";
import { FormGroup } from "@angular/forms";
import { PackageProductMapPipe } from "src/app/pipes/package-product-map.pipe";
import { BusinessProposalService } from "src/app/services/business-proposal.service";
import {
  createExtraServicesForm,
  filterAdditionalPackages
} from "./extra-services-form.helper";
import { forkJoin, from, Observable, of, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { PageContainerComponent } from "../../layout/page-container/page-container.component";
import { NgIf, NgFor } from "@angular/common";
import { LoadingComponent } from "../../layout/loading/loading.component";
import { RequestFailedComponent } from "../../layout/request-failed/request-failed.component";
import { PackageContainerComponent } from "../../layout/package-container/package-container.component";
import { ViewMoreComponent } from "../../layout/view-more/view-more.component";
import { TranslateModule } from "@ngx-translate/core";

@Component({
  selector: "app-extra-services",
  templateUrl: "./extra-services.component.html",
  standalone: true,
  imports: [
    PageContainerComponent,
    NgIf,
    LoadingComponent,
    RequestFailedComponent,
    NgFor,
    PackageContainerComponent,
    ViewMoreComponent,
    TranslateModule
  ]
})
export class ExtraServicesComponent
  extends PageBaseComponent
  implements OnInit, OnDestroy
{
  public PackageTagEnum: typeof PackageTagEnum = PackageTagEnum;

  constructor(
    public formService: FormService,
    private pagingService: PagingService,
    public extenderService: ExtenderService,
    private trackingService: TrackingService,
    public priceService: PriceService,
    private packageProductMapPipe: PackageProductMapPipe,
    private businessProposalService: BusinessProposalService
  ) {
    super();
    this.formService.extraServicesForm.markAsPristine();
    this.formService.previousFormValue =
      this.formService.extraServicesForm.value;
  }
  ngOnDestroy(): void {
    if (
      !this.formService.extraServicesForm.pristine &&
      !this.trackingService.hasTrackedAddonsOnce
    )
      this.trackingService.trackAddonChosen(
        this.formService.getAdditionalSelectionAsProducts()
      );
    this.cleanUp();
  }

  private handleGetPackageError(
    err: SwaggerException | ErrorResponse
  ): Observable<PackageResponse[]> {
    if (err.status === 404) {
      return of([]);
    }
    return throwError(err);
  }

  ngOnInit(): void {
    this.formService.extraServicesForm.markAsPristine();
    if (
      !!this.formService.additionalPackagesResponse &&
      !this.formService.extraServiceLoadedInStep4
    ) {
      this.state = this.ComponentStateEnum.Initialized;
      this.trackListView();
      return;
    }

    this.state = this.ComponentStateEnum.Loading;
    const observables = [];
    observables.push(
      from(
        new Client().apiV2PackageWashAndRecond(
          new PackageRequest({
            chassiNumber: this.formService.carInfoResponse.chassiNumber,
            dealerNumber: this.formService.selectedWorkshop.number,
            odometer: this.formService.milageValue * 10
          })
        )
      ).pipe(catchError(this.handleGetPackageError))
    );
    observables.push(
      from(
        new Client().apiV2PackageServiceAdditional(
          new PackageRequest({
            chassiNumber: this.formService.carInfoResponse.chassiNumber,
            dealerNumber: this.formService.selectedWorkshop.number,
            odometer: this.formService.milageValue * 10
          })
        )
      ).pipe(catchError(this.handleGetPackageError))
    );
    observables.push(
      from(
        new Client().apiV2PackageDealerExtra(
          new PackageRequest({
            chassiNumber: this.formService.carInfoResponse.chassiNumber,
            dealerNumber: this.formService.selectedWorkshop.number,
            odometer: this.formService.milageValue * 10
          })
        )
      ).pipe(catchError(this.handleGetPackageError))
    );

    forkJoin(observables)
      .toPromise()
      .then(
        (response: PackageResponse[][]) => {
          const packages: PackageResponse[] = [].concat.apply([], response);
          const proposal = this.businessProposalService.businessProposal$.value;
          this.formService.extraServiceLoadedInStep4 = false;
          this.formService.additionalPackagesResponse =
            filterAdditionalPackages(this.formService, packages, proposal);

          this.trackingService.hasTrackedAddonsOnce = false;
          this.trackListView();
          this.initForm();
          this.state = this.ComponentStateEnum.Initialized;
        },
        (reason: SwaggerException) => {
          if (reason.status === 404 || reason.status === 503)
            this.pagingService.navigateToServiceUnavailable();
          this.state = this.ComponentStateEnum.ApiCallFailed;
        }
      );
  }

  initForm(): void {
    const newForm = createExtraServicesForm(this.formService);
    this.formService.updateForm(newForm);
    this.formService.extraServicesForm.markAsTouched();
  }

  extraServicesParentForm(id: string): FormGroup<any> {
    return this.formService.extraServicesForm.get(id) as FormGroup<any>;
  }

  get packages(): PackageResponse[] {
    return this.formService.additionalPackagesResponse
      ? this.formService.additionalPackagesResponse
      : [];
  }

  get serviceAdditionalPackages(): PackageResponse[] {
    // "PPS" type packages might exist on proposal packages, add them here
    const packages = this.packages.filter((j: PackageResponse) =>
      [PackageTypeEnum.ServiceAdditional, PackageTypeEnum.PPS].includes(j.type)
    );
    return packages ? packages : [];
  }

  get dealerExtraPackages(): PackageResponse[] {
    const packages = this.packages.filter(
      (j: PackageResponse) =>
        j.type === PackageTypeEnum.DealerExtra ||
        j.type === PackageTypeEnum.WashAndRecond
    );
    return packages ? packages : [];
  }
  private trackListView(): void {
    this.trackingService.trackItemListViewed(
      "Step5",
      [].concat.apply(
        [],
        this.formService.additionalPackagesResponse.map((x) =>
          [
            this.packageProductMapPipe.transform(
              x,
              this.formService.carInfoResponse?.brandName
            )
          ].concat(
            x.subPackages.map((sp) =>
              this.packageProductMapPipe.transform(
                sp,
                this.formService.carInfoResponse?.brandName
              )
            )
          )
        )
      )
    );
  }
}
