import { Component, Input } from "@angular/core";
import { FormService } from "src/app/services/form.service";
import { FirstToUpperPipe } from "../../../pipes/sharedpipes/first-to-upper.pipe";
import { TranslateModule } from "@ngx-translate/core";
import { RoadAssistantLinkPipe } from "../../../pipes/road-assistant-link.pipe";
import { BaseServiceLinkPipe } from "../../../pipes/base-service-link.pipe";
import { CarBrandPipe } from "../../../pipes/car-brand.pipe";
import { IPackageResponse, PackageResponse, PackageTagEnum } from "src/domain/client";
import { BusinessProposalService } from "src/app/services/business-proposal.service";
import { NgFor, NgIf } from "@angular/common";
import { IconComponent } from "../icon/icon.component";
import { PackagePricePipe } from "src/app/pipes/package-price.pipe";
import { IconService } from "src/app/services/icon.service";

@Component({
  selector: "app-base-service-package-info",
  templateUrl: "./base-service-package-info.component.html",
  standalone: true,
  imports: [
    FirstToUpperPipe,
    TranslateModule,
    RoadAssistantLinkPipe,
    BaseServiceLinkPipe,
    CarBrandPipe,
    NgFor,
    NgIf,
    IconComponent,
    PackagePricePipe
  ]
})
export class BaseServicePackageInfoComponent {
  @Input() package: IPackageResponse;

  PackageTagEnum = PackageTagEnum;
  constructor(
    public formService: FormService,
    private businessProposalService: BusinessProposalService,
    public iconService: IconService
  ) {}

  get showSubpackages(): boolean {
    return (
      this.businessProposalService.isBusinessProposalActive &&
      this.package.subPackages.length > 1
    );
  }

  get subPackages(): PackageResponse[] {
    return this.package.subPackages;
  }
}
