import { FormGroup, FormControl } from "@angular/forms";
import { FormService } from "src/app/services/form.service";
import {
  BusinessProposalResponse,
  BusinessProposalStatusEnum,
  PackageResponse,
  PackageTagEnum,
  PackageTypeEnum
} from "src/domain/client";

export function createExtraServicesForm(formService: FormService) {
  const packages = formService.additionalPackagesResponse ?? [];
  const formExtraServicesValues = formService.extraServicesForm.value;
  const serviceAdditionalPackages = packages.filter((j: PackageResponse) =>
    [PackageTypeEnum.ServiceAdditional, PackageTypeEnum.PPS].includes(j.type)
  );
  const dealerExtraPackages = packages.filter(
    (j: PackageResponse) =>
      j.type === PackageTypeEnum.DealerExtra ||
      j.type === PackageTypeEnum.WashAndRecond
  );
  const newForm = new FormGroup<any>(
    {},
    formService.secureStateOnChanges(formService)
  );
  serviceAdditionalPackages.forEach((p: PackageResponse) => {
    const tmpForm = new FormGroup<any>({});
    tmpForm.addControl(
      "selected",
      new FormControl(
        p.tags.includes(PackageTagEnum.Recommended) ||
          formExtraServicesValues[p.id]?.selected
      )
    );
    if (!p.isSubCategory) {
      p.subPackages.forEach((sp: PackageResponse) => {
        tmpForm.addControl(
          sp.id,
          new FormControl({
            value: false,
            disabled: false
          })
        );
      });
    } else {
      const value = p.subPackages.find((pkg) =>
        pkg.tags.includes(PackageTagEnum.Recommended)
      );

      tmpForm.addControl(
        "subcategoryContainer",
        new FormControl({
          value: value?.id || "",
          disabled: false
        })
      );
    }
    newForm.addControl(p.id, tmpForm);
  });
  dealerExtraPackages.forEach((p: PackageResponse) => {
    const tmpForm = new FormGroup<any>({});
    tmpForm.addControl(
      "selected",
      new FormControl(
        p.tags.includes(PackageTagEnum.Recommended) ||
          formExtraServicesValues[p.id]?.selected
      )
    );
    if (!p.isSubCategory) {
      p.subPackages.forEach((sp: PackageResponse) => {
        tmpForm.addControl(
          sp.id,
          new FormControl({
            value: false,
            disabled: false
          })
        );
      });
    } else {
      // find the selected subpackage if any
      const value = p.subPackages.find((pkg) =>
        pkg.tags.includes(PackageTagEnum.Recommended)
      );

      tmpForm.addControl(
        "subcategoryContainer",
        new FormControl({
          value: value?.id || "",
          disabled: false
        })
      );
    }

    newForm.addControl(p.id, tmpForm);
  });
  return newForm;
}

export function filterAdditionalPackages(
  formService: FormService,
  packages: PackageResponse[],
  proposal: BusinessProposalResponse
) {
  const filteredPackages = packages
    .filter((x) => !!x)
    .map((x) => {
      const matchingProposalPackage =
        formService.proposalExtraServicePackages.find((p) => {
          if (x.packageExternalId) {
            return p.packageExternalId === x.packageExternalId;
          }
          return p.id === x.id;
        });

      if (
        matchingProposalPackage &&
        proposal?.businessProposalStatus !== BusinessProposalStatusEnum.Expired
      ) {
        if (matchingProposalPackage.isSubCategory) {
          const subPackages = x.subPackages.map((subPkg) => {
            const index = matchingProposalPackage.subPackages.findIndex(
              (s) => s.packageExternalId === subPkg.id
            );
            if (index !== -1) {
              return matchingProposalPackage.subPackages[index];
            }

            return subPkg;
          });

          return new PackageResponse({
            ...matchingProposalPackage,
            subPackages
          });
        }
        // return the proposal package in case it differs from the fetched package. Proposal package has priority.
        return matchingProposalPackage;
      }

      return x;
    });

  // See if any proposal package does not exist among packages. If not, add it
  formService.proposalExtraServicePackages.forEach((p) => {
    const matchingPackage = filteredPackages.find((x) => x.id === p.id);

    if (!matchingPackage) {
      // Add package to front of array. Logical since it'll be checked
      filteredPackages.unshift(p);
    }
  });

  return filteredPackages;
}
