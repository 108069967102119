import { Injectable, OnDestroy } from "@angular/core";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { map, takeUntil } from "rxjs/operators";
import { FormControl, FormGroup } from "@angular/forms";
import { ServiceLinkHttpService } from "./service-link-http.service";
import {
  ProposalItemResponse,
  ProposalResponse,
  ProposalStatusEnum
} from "src/domain/service-link/serviceLinkClient";
import { environment } from "src/environments/environment";

export type ProposalTouchedItem = {
  item: ProposalItemResponse;
  value: boolean;
};

@Injectable({
  providedIn: "root"
})
export class ServiceLinkStoreService implements OnDestroy {
  private destroy$ = new Subject<void>();

  public form = new FormGroup({});

  public proposal: ProposalResponse = undefined;

  public isPreview = false;

  private touchedProposalsSub: BehaviorSubject<ProposalTouchedItem[]> =
    new BehaviorSubject<ProposalTouchedItem[]>([]);

  public touchedProposals$ = this.touchedProposalsSub.asObservable();

  private selectedProposals$ = this.touchedProposals$.pipe(
    map((x) => x.filter((i) => i.value === true).map((i) => i.item))
  );

  proposalPriceSum$: Observable<number> = this.selectedProposals$.pipe(
    map((selectedProposals) =>
      selectedProposals
        .map((proposal) => proposal.price || 0)
        .reduce((acc, price) => acc + price, 0)
    )
  );

  constructor(private serviceLinkHttpService: ServiceLinkHttpService) {}

  async load(proposalId: string, isPreview: boolean = false) {
    this.proposal = null;
    this.isPreview = isPreview;
    this.form = new FormGroup({});

    await this.serviceLinkHttpService.get(proposalId).then((proposal) => {
      this.proposal = proposal;
      this.proposal.proposalItems.forEach((x) => {
        this.form.addControl(x.id.toString(), new FormControl(null));
      });
    });
  }

  updateFormValues() {
    if (!this.proposal) return;

    this.proposal.proposalItems.forEach((item) => {
      const control = this.form.controls[item.id.toString()];
      if (control) {
        control.setValue(item.customerAccepted);
      } else {
        console.warn(`Control with id ${item.id.toString()} does not exist.`);
      }
    });
  }

  isStatusValid(): boolean {
    return (
      this.proposal.status === ProposalStatusEnum.Sent ||
      this.proposal.status === ProposalStatusEnum.Opened
    );
  }

  isStatusCustomerReviewed(): boolean {
    return (
      this.proposal.status === ProposalStatusEnum.CustomerApproved ||
      this.proposal.status === ProposalStatusEnum.CustomerRejected
    );
  }

  getStatus(): ProposalStatusEnum {
    return this.proposal.status;
  }

  async initialize() {
    this.form.valueChanges
      .pipe(
        takeUntil(this.destroy$),
        map((currentValues: Record<string, boolean>) => {
          return this.proposal.proposalItems.map(
            (item) =>
              ({
                item,
                value: currentValues[item.id]
              }) as ProposalTouchedItem
          );
        })
      )
      .subscribe((result) => {
        this.touchedProposalsSub.next(result);
      });
  }

  respond(): Promise<any> {
    const selectedItemIds = this.touchedProposalsSub
      .getValue()
      .filter((x) => x.value === true)
      .map((x) => x.item.id);

    return this.serviceLinkHttpService
      .respond(this.proposal.id, selectedItemIds)
      .then(() => {
        this.proposal.status = ProposalStatusEnum.CustomerApproved;
      })
      .catch((error) => {
        if (environment.production === false) {
          console.log(error);
        }
      });
  }

  respondNotNow(): Promise<any> {
    return this.serviceLinkHttpService
      .respond(this.proposal.id, [])
      .then(() => {
        this.proposal.status = ProposalStatusEnum.CustomerRejected;
      })
      .catch((error) => {
        if (environment.production === false) {
          console.log(error);
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
