import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormControl, FormGroup, ReactiveFormsModule } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { expandAnimation } from "src/app/animations/expand";
import { IViewPair } from "src/app/interfaces/interfaces";
import { ExtenderService } from "src/app/services/extender.service";
import { FormService } from "src/app/services/form.service";
import { IconService } from "src/app/services/icon.service";
import { PagingService } from "src/app/services/paging.service";
import { TrackingService } from "src/app/services/tracking.service";
import {
  Client,
  VehicleWarningLightsResponse,
  SwaggerException,
  WorkshopSystemEnum,
  PackageResponse,
  PackageTypeEnum,
  ExternalTyreUrlRequest,
  ExternalTyreUrlResponse,
  InsuranceCompanyRequest,
  InsuranceCompanyResponse,
  VehicleSegmentRequest,
  PackageTagEnum,
  PackageRequest,
  BusinessProposalStatusEnum
} from "src/domain/client";
import {
  DialogTypeEnum,
  InfoCardTypesEnum,
  PageFormNameEnum
} from "src/app/classes/enums";
import { PackageService } from "src/app/services/package.service";
import { DialogComponent } from "../../layout/dialog/dialog.component";
import { PageBaseComponent } from "../../base/page-base/page-base.component";
import { PackagesFilterPipe } from "src/app/pipes/packages-filter.pipe";
import { PackagesServiceFilterPipe } from "src/app/pipes/packages-service-filter.pipe";
import { PackageProductMapPipe } from "src/app/pipes/package-product-map.pipe";
import { CarBrandPipe } from "src/app/pipes/car-brand.pipe";
import { BusinessProposalService } from "src/app/services/business-proposal.service";
import { environment } from "src/environments/environment";
import { RejectProposalDialogComponent } from "../../layout/reject-proposal-dialog/reject-proposal-dialog.component";
import { createChooseSpecificationsForm } from "./choose-specification-form.helper";
import { PageContainerComponent } from "../../layout/page-container/page-container.component";
import { NgIf, NgFor } from "@angular/common";
import { LoadingComponent } from "../../layout/loading/loading.component";
import { RequestFailedComponent } from "../../layout/request-failed/request-failed.component";
import { SectionTogglerComponent } from "../../layout/section-toggler/section-toggler.component";
import { MatRadioGroup } from "@angular/material/radio";
import { PackageContainerComponent } from "../../layout/package-container/package-container.component";
import { InfoCardComponent } from "../../layout/info-card/info-card.component";
import { MatDivider } from "@angular/material/divider";
import { FaultReportComponent } from "../../layout/fault-report/fault-report.component";
import { WindscreenWizardComponent } from "../../layout/windscreen-wizard/windscreen-wizard.component";
import { ShowProposalPpsPackagesComponent } from "../show-proposal-pps-packages/show-proposal-pps-packages.component";
import { TextAreaComponent } from "../../inputs/text-area/text-area.component";
import { CheckboxCardComponent } from "../../layout/checkbox-card/checkbox-card.component";
import { BrandCardComponent } from "../../layout/brand-card/brand-card.component";
import { TranslateModule } from "@ngx-translate/core";
import { CarBrandPipe as CarBrandPipe_1 } from "../../../pipes/car-brand.pipe";
import { PackagesFilterPipe as PackagesFilterPipe_1 } from "../../../pipes/packages-filter.pipe";
import { PackagesServiceFilterPipe as PackagesServiceFilterPipe_1 } from "../../../pipes/packages-service-filter.pipe";
import { IActiveWorkshopSystemResponseBusinessProposal } from "src/app/classes/active-workshop-system-response-business-proposal";
import { AdditionalServicesCheckboxComponent } from "../../layout/additional-services-checkbox/additional-services-checkbox.component";
import {
  createExtraServicesForm,
  filterAdditionalPackages
} from "../extra-services/extra-services-form.helper";
@Component({
  selector: "app-choose-specifications",
  templateUrl: "./choose-specifications.component.html",
  animations: [expandAnimation],
  standalone: true,
  imports: [
    PageContainerComponent,
    NgIf,
    LoadingComponent,
    RequestFailedComponent,
    SectionTogglerComponent,
    ReactiveFormsModule,
    MatRadioGroup,
    NgFor,
    PackageContainerComponent,
    InfoCardComponent,
    MatDivider,
    FaultReportComponent,
    WindscreenWizardComponent,
    ShowProposalPpsPackagesComponent,
    TextAreaComponent,
    CheckboxCardComponent,
    BrandCardComponent,
    TranslateModule,
    CarBrandPipe_1,
    PackagesFilterPipe_1,
    PackagesServiceFilterPipe_1,
    AdditionalServicesCheckboxComponent
  ]
})
export class ChooseSpecificationsComponent
  extends PageBaseComponent
  implements OnInit, OnDestroy
{
  public isLoadingFile = false;

  public serviceToggle = false;
  public troubleshootingToggle = false;
  public wheelToggle = false;
  public windscreenToggle = false;
  public ppsToggle = false;
  public techUpdateToggle = false;
  public otherToggle = false;
  public additionalServicesToogle = false;
  public firstTimeSelect = true;
  public loadingAfterServiceChange = false;
  public additionalServicesAdded = false;

  public insuranceViewPairs: IViewPair[] = null;
  public additionalServices: PackageResponse[] = [];

  public WorkshopSystemEnum = WorkshopSystemEnum;
  public InfoCardTypeEnum = InfoCardTypesEnum;
  public PackageTypeEnum = PackageTypeEnum;

  constructor(
    public formService: FormService,
    public iconService: IconService,
    public extenderService: ExtenderService,
    private pagingService: PagingService,
    private trackingService: TrackingService,
    public dialog: MatDialog,
    private carBrandPipe: CarBrandPipe,
    private packageService: PackageService,
    private packageFilterPipe: PackagesFilterPipe,
    private packageServiceFilterPipe: PackagesServiceFilterPipe,
    private packageProductMapPipe: PackageProductMapPipe,
    public businessProposalService: BusinessProposalService
  ) {
    super();
    this.formService.chooseSpecificationsForm.markAsUntouched();
    this.formService.chooseSpecificationsForm.markAsPristine();
    this.formService.previousFormValue =
      this.formService.chooseSpecificationsForm.value;
  }

  ngOnDestroy(): void {
    if (
      !this.formService.chooseSpecificationsForm.pristine ||
      (!this.trackingService.hasTrackedServiceTypesOnce &&
        !!this.formService.basePackagesResponse)
    ) {
      const matchingPackages = (
        this.formService.basePackagesResponse || []
      ).filter((x) =>
        this.formService.selectedBasePackages
          .map((z) => z.packageId.toString())
          .includes(x.id)
      );
      const matchingSubPackages = matchingPackages.flatMap((p) =>
        p.subPackages.filter((x) =>
          this.formService.selectedBasePackages
            .find((sl) => sl.packageId.toString() === p.id)
            .subPackageIds.includes(x.id)
        )
      );
      this.trackingService.trackServiceTypeChosen(
        !this.formService.chooseSpecificationsForm.pristine,
        matchingPackages,
        matchingSubPackages
      );
    }

    this.cleanUp();
  }

  ngOnInit(): void {
    if (this.formService.selectedWorkshop.whatsAppEnabled) {
      this.insertSoultechScript();
    }
    if (!this.formService.segment) {
      new Client()
        .apiV2VehicleGetSegment(
          new VehicleSegmentRequest({
            chassiNumber: this.formService.carInfoResponse.chassiNumber,
            dealerNumber: this.formService.selectedWorkshop.number
          })
        )
        .then((segment: number) => (this.formService.segment = segment))
        .catch((reason) => console.log(reason));
    }
    if (!this.formService.externalTyreUrlResponse) {
      new Client()
        .apiV2DealerExternalTyre(
          new ExternalTyreUrlRequest({
            chassiNumber: this.formService.carInfoResponse.chassiNumber,
            dealerNumber: this.formService.selectedWorkshop.number
          })
        )
        .then((response: ExternalTyreUrlResponse) => {
          this.formService.externalTyreUrlResponse = response;
          if (
            !!response?.externalTyreUrl &&
            this.formService.serviceTypeSelection.length === 1 &&
            this.formService.serviceTypeSelection.includes(
              WorkshopSystemEnum.Wheel
            )
          ) {
            this.formService.rejectedIsOnlyTyre = true;
            document.getElementById("vbo-header").scrollIntoView(true);
            const ref = this.dialog.open(DialogComponent, {
              data: {
                componentName: DialogTypeEnum.externalTyre,
                title: "Däckbokningen befinner sig på en extern sida",
                brand: this.carBrandPipe.transform(
                  this.formService.carInfoResponse
                )
              },
              maxWidth: "700px",
              width: "90%",
              disableClose: true
            });
            ref.afterClosed().subscribe((result: boolean) => {
              if (result) {
                let url = response.externalTyreUrl;
                if (!url.startsWith("http://") && !url.startsWith("https://")) {
                  url = "https://" + url;
                }
                window.location.href = url;
              }
            });
          }
        });
    }

    this.subscription.add(
      this.formService.workshopSystemSelectionForm.valueChanges.subscribe({
        next: async () => {
          try {
            this.loadingAfterServiceChange = true;
            await this.getPackagesForStep(true);
            this.loadingAfterServiceChange = false;
          } catch (err) {
            this.loadingAfterServiceChange = false;
          }
        }
      })
    );
    this.handleAdditionalPackages();

    if (this.formService.basePackagesResponse) {
      if (
        this.formService.serviceTypeSelection.length !==
          Object.keys(this.formService.chooseSpecificationsForm.value).length -
            1 &&
        !this.businessProposalService.isBusinessProposalActive
      ) {
        this.extrasInitializer();
        return;
      }
      if (this.formService.insuranceProvidersResponse?.length) {
        this.mapInsuranceViewPairs();
      }
      this.formService.chooseSpecificationsForm.markAsTouched();
      this.state = this.ComponentStateEnum.Initialized;
      this.trackListView();
      return;
    }
    this.state = this.ComponentStateEnum.Loading;
    this.getPackagesForStep();
  }

  private getGroupedProposalPackages(): {
    extraServicePackages: PackageResponse[];
    other: PackageResponse[];
  } {
    const proposal = this.businessProposalService.businessProposal$.value;
    const extraServicePackageType = [
      PackageTypeEnum.PPS,
      PackageTypeEnum.WashAndRecond,
      PackageTypeEnum.ServiceAdditional,
      PackageTypeEnum.DealerExtra
    ];
    return (proposal?.packages || []).reduce(
      (acc, p) => {
        if (extraServicePackageType.includes(p.type)) {
          acc.extraServicePackages.push(p);
        } else {
          acc.other.push(p);
        }

        return acc;
      },
      {
        extraServicePackages: [],
        other: []
      }
    );
  }

  private handleAdditionalPackages() {
    if (!this.formService.additionalPackagesResponse?.length) {
      const proposal = this.businessProposalService.businessProposal$.value;
      this.formService.extraServiceLoadedInStep4 = true;
      const ODOMETER_MULTIPLIER = 10;

      new Client()
        .apiV2PackageServiceAdditional(
          new PackageRequest({
            chassiNumber: this.formService.carInfoResponse.chassiNumber,
            dealerNumber: this.formService.selectedWorkshop.number,
            odometer: this.formService.milageValue * ODOMETER_MULTIPLIER
          })
        )
        .then((packages) => {
          this.formService.additionalPackagesResponse =
            filterAdditionalPackages(this.formService, packages, proposal);
          if (
            proposal?.businessProposalStatus !==
            BusinessProposalStatusEnum.Expired
          ) {
            this.additionalServices = packages.filter((x) => {
              const matchingProposalPackage =
                this.formService.proposalExtraServicePackages.find((p) => {
                  if (x.packageExternalId) {
                    return p.packageExternalId === x.packageExternalId;
                  }
                  return p.id === x.id;
                });

              return !matchingProposalPackage;
            });
          } else {
            this.additionalServices = packages;
          }

          const newForm = createExtraServicesForm(this.formService);
          this.formService.form.removeControl("extraServicesForm");
          this.formService.form.addControl("extraServicesForm", newForm);
          this.formService.extraServicesForm.markAsTouched();
        });
    } else {
      this.additionalServices =
        this.formService.additionalPackagesResponse.filter((pkg) => {
          const serviceAdditionalPackage =
            pkg.type === PackageTypeEnum.ServiceAdditional;

          const matchingProposalPackage =
            this.formService.proposalExtraServicePackages.find((p) => {
              if (pkg.packageExternalId) {
                return p.packageExternalId === pkg.packageExternalId;
              }
              return p.id === pkg.id;
            });

          return serviceAdditionalPackage && !matchingProposalPackage;
        });
    }
  }

  private async getPackagesForStep(workshopSystemChanged = false) {
    if (
      this.businessProposalService.isBusinessProposalActive &&
      !workshopSystemChanged
    ) {
      const dropOffPackages = await this.packageService.getDropOffPackages();
      const groupedPackages = this.getGroupedProposalPackages();
      this.formService.proposalExtraServicePackages =
        groupedPackages.extraServicePackages;

      // Create map to remove duplicates. Needed if packages in businessProposal
      const basePackagesSet = new Map<string, PackageResponse>();
      groupedPackages.other.forEach((p) => {
        basePackagesSet.set(p.id, p);
      });
      dropOffPackages.forEach((p) => {
        basePackagesSet.set(p.id, p);
      });
      // Convert set to array when assigning to property
      this.formService.basePackagesResponse = [...basePackagesSet.values()];
      this.trackingService.hasTrackedServiceTypesOnce = false;
      // this.trackListView();
      this.initExtraServicesFormForStep5();
      await this.extrasInitializer();
      return;
    }

    return this.packageService
      .getAllPackagesForStep4(this.businessProposalService.proposalServices)
      .then(
        async (packages: PackageResponse[]) => {
          const groupedProposalPackages = this.getGroupedProposalPackages();
          this.formService.basePackagesResponse = [
            ...packages,
            ...groupedProposalPackages.other
          ];
          this.trackingService.hasTrackedServiceTypesOnce = false;
          this.trackListView();
          await this.extrasInitializer();
        },
        (reason: SwaggerException) => {
          if (reason.status === 404 || reason.status === 503)
            this.pagingService.navigateToServiceUnavailable();
          this.state = this.ComponentStateEnum.ApiCallFailed;
        }
      )
      .catch((reason) => {
        console.log(reason);
      });
  }

  // Applies when proposal is active and extra services exists
  // Add extra services in step 5 for price summary to be correct in step 4
  private initExtraServicesFormForStep5() {
    if (!this.businessProposalService.isBusinessProposalActive) return;

    this.formService.additionalPackagesResponse = [
      ...this.formService.proposalExtraServicePackages
    ];

    const serviceAdditionalPackages = (): PackageResponse[] => {
      // "PPS" type packages might exist on proposal packages, add them here
      const packages = this.formService.proposalExtraServicePackages?.filter(
        (j: PackageResponse) =>
          [PackageTypeEnum.ServiceAdditional, PackageTypeEnum.PPS].includes(
            j.type
          )
      );
      return packages ? packages : [];
    };

    const dealerExtraPackages = (): PackageResponse[] => {
      const packages = this.formService.proposalExtraServicePackages?.filter(
        (j: PackageResponse) =>
          j.type === PackageTypeEnum.DealerExtra ||
          j.type === PackageTypeEnum.WashAndRecond
      );
      return packages ? packages : [];
    };

    const newForm = new FormGroup<any>(
      {},
      this.formService.secureStateOnChanges(this.formService)
    );
    serviceAdditionalPackages().forEach((p: PackageResponse) => {
      const tmpForm = new FormGroup<any>({});
      tmpForm.addControl(
        "selected",
        new FormControl(p.tags.includes(PackageTagEnum.Recommended))
      );
      if (!p.isSubCategory) {
        p.subPackages.forEach((sp: PackageResponse) => {
          tmpForm.addControl(
            sp.id,
            new FormControl({
              value: false,
              disabled: false
            })
          );
        });
      } else {
        const value = p.subPackages.find((pkg) =>
          pkg.tags.includes(PackageTagEnum.Recommended)
        );

        tmpForm.addControl(
          "subcategoryContainer",
          new FormControl({
            value: value.id || "",
            disabled: false
          })
        );
      }
      newForm.addControl(p.id, tmpForm);
    });

    dealerExtraPackages().forEach((p: PackageResponse) => {
      const tmpForm = new FormGroup<any>({});
      tmpForm.addControl(
        "selected",
        new FormControl(p.tags.includes(PackageTagEnum.Recommended))
      );
      if (!p.isSubCategory) {
        p.subPackages.forEach((sp: PackageResponse) => {
          tmpForm.addControl(
            sp.id,
            new FormControl({
              value: false,
              disabled: false
            })
          );
        });
      } else {
        const value = p.subPackages.find((pkg) =>
          pkg.tags.includes(PackageTagEnum.Recommended)
        );

        tmpForm.addControl(
          "subcategoryContainer",
          new FormControl({
            value: value.id || "",
            disabled: false
          })
        );
      }

      newForm.addControl(p.id, tmpForm);
    });

    (this.formService.form as FormGroup<any>).removeControl(
      PageFormNameEnum.extraServices
    );
    (this.formService.form as FormGroup<any>).addControl(
      PageFormNameEnum.extraServices,
      newForm
    );
    this.formService.extraServicesForm.markAsTouched();
    this.formService.extraServiceLoadedInStep4 = true;
  }

  get serviceForm(): FormGroup<any> {
    return this.formService.chooseSpecificationsForm.controls.serviceForm;
  }

  get troubleshootingForm(): FormGroup<any> {
    return this.formService.chooseSpecificationsForm.controls
      .troubleshootingForm as FormGroup<any>;
  }
  get wheelForm(): FormGroup<any> {
    return this.formService.chooseSpecificationsForm.controls.wheelForm;
  }
  get windscreenForm(): FormGroup<any> {
    return this.formService.chooseSpecificationsForm.controls
      .windscreenForm as FormGroup<any>;
  }

  get extraServicesForm(): FormGroup<any> {
    return this.formService.form.controls.extraServicesForm;
  }

  get techUpdateForm(): FormGroup<any> {
    return this.formService.chooseSpecificationsForm.controls.techUpdateForm;
  }

  getPackageFormGroup(form: FormGroup<any>, id: string): FormGroup<any> {
    return form.get(id) as FormGroup<any>;
  }

  selectService(): void {
    const service = this.packageServiceFilterPipe
      .transform(this.packages)
      .find((x) => x.type === PackageTypeEnum.OriginalService);
    if (service) {
      const selected = this.serviceForm.get("selectedContainer");
      selected.setValue(service.id);
      this.firstTimeSelect = false;
    }
  }

  getOriginalServiceName(
    pkg: PackageResponse,
    servicePackages: PackageResponse[]
  ): string {
    if (!this.firstTimeSelect) {
      return null;
    }
    if (
      !servicePackages.map((j) => j.type).includes(PackageTypeEnum.BaseService)
    ) {
      return null;
    }
    const originalService = servicePackages.find(
      (x) => x.type === PackageTypeEnum.OriginalService
    );
    const selected = this.serviceForm.get("selectedContainer");
    if (selected?.value === originalService?.id) return null;
    return pkg === originalService ? originalService.name : null;
  }

  // Should be a Pipe
  collapseService(
    pkg: PackageResponse,
    servicePackages: PackageResponse[]
  ): boolean {
    if (!this.firstTimeSelect) {
      return false;
    }
    if (
      !servicePackages.map((j) => j.type).includes(PackageTypeEnum.BaseService)
    ) {
      return false;
    }
    const selected = this.serviceForm.get("selectedContainer");
    if (selected?.value === pkg?.id) return false;
    const originalService = servicePackages.find(
      (x) => x.type === PackageTypeEnum.OriginalService
    );
    return pkg?.id === originalService?.id;
  }

  get packages(): PackageResponse[] {
    return this.formService.basePackagesResponse
      ? this.formService.basePackagesResponse
      : [];
  }

  get proposalPackages(): PackageResponse[] {
    return this.businessProposalService.businessProposal$?.value.packages || [];
  }

  async extrasInitializer(): Promise<void> {
    if (
      this.formService.serviceTypeSelection.includes(
        WorkshopSystemEnum.Windscreen
      )
    ) {
      if (!this.formService.insuranceProvidersResponse?.length)
        await this.initInsuranceProviders();
    }
    if (
      this.formService.serviceTypeSelection.includes(
        WorkshopSystemEnum.Troubleshooting
      )
    ) {
      if (!this.formService.warningLightsResponse)
        await this.initWarningLights();
    }
    this.initForm();
    this.state = this.ComponentStateEnum.Initialized;
  }

  mapInsuranceViewPairs(): void {
    this.insuranceViewPairs = this.formService.insuranceProvidersResponse.map(
      (i: InsuranceCompanyResponse) => {
        return {
          value: i.insuranceCompanyId.toString(),
          view: i.name
        };
      }
    );
  }

  async initWarningLights(): Promise<void> {
    return new Client().apiV2VehicleAllWarningLights().then(
      (response: VehicleWarningLightsResponse[]) => {
        this.formService.warningLightsResponse = response;
      },
      (reason: SwaggerException) => {
        if (reason.status === 404 || reason.status === 503)
          this.pagingService.navigateToServiceUnavailable();
      }
    );
  }

  async initInsuranceProviders(): Promise<void> {
    return new Client()
      .apiV2InsuranceCompanyActiveInsuranceCompanies(
        new InsuranceCompanyRequest({
          dealerNumber: this.formService.selectedWorkshop.number
        })
      )
      .then(
        (response: InsuranceCompanyResponse[]) => {
          this.formService.insuranceProvidersResponse = response;
          this.mapInsuranceViewPairs();
        },
        (reason: SwaggerException) => {
          if (reason.status === 404 || reason.status === 503)
            this.pagingService.navigateToServiceUnavailable();
        }
      );
  }

  initForm(): void {
    const newForm = createChooseSpecificationsForm(
      this.formService,
      this.packageServiceFilterPipe,
      this.packageFilterPipe,
      this.businessProposalService
    );
    this.formService.updateForm(newForm);
    this.formService.chooseSpecificationsForm.markAsTouched();
  }

  getFormTypeInteractions(): string[] {
    return this.formService.serviceTypeSelection.filter(
      (t) =>
        !!Object.keys(this.formService.chooseSpecificationsForm.controls).find(
          (v) =>
            !!v.toUpperCase().includes(t.toUpperCase()) &&
            !!this.formService.chooseSpecificationsForm
              .get(v)
              .get("selectedContainer") &&
            !!this.formService.chooseSpecificationsForm
              .get(v)
              .get("selectedContainer").value
        )
    );
  }

  private insertSoultechScript(): void {
    const hasSoultechScript = !!document.getElementById("soultech");
    if (hasSoultechScript) return;

    const node = document.createElement("script");
    node.id = "soultech";
    node.src = environment.soultechScriptUrl;
    node.async = true;
    document.getElementsByTagName("head")[0].appendChild(node);
  }

  private trackListView(): void {
    const packages = this.formService.basePackagesResponse.filter(
      (x) =>
        x.type !== PackageTypeEnum.CheckInOption &&
        x.type !== PackageTypeEnum.DropoffOption &&
        x.type !== PackageTypeEnum.Mobility
    );
    const products = packages.flatMap((x) => [
      this.packageProductMapPipe.transform(
        x,
        this.formService.carInfoResponse?.brandName
      ),
      ...x.subPackages.map((sp) =>
        this.packageProductMapPipe.transform(
          sp,
          this.formService.carInfoResponse?.brandName
        )
      )
    ]);

    this.trackingService.trackItemListViewed("Step4", products);
  }

  getFilteredWorkshopSystems() {
    return (
      this.formService
        .activeWorkshopSystemsResponse as IActiveWorkshopSystemResponseBusinessProposal[]
    ).filter((item) => {
      // If item is selected earlier when loading business proposal, remove it from this list
      if (
        item.selectedFromBusinessProposal ||
        item.workshopSystemId === WorkshopSystemEnum.Other
      ) {
        return false;
      }

      return true;
    });
  }

  openRejectBusinessProposal(e: MouseEvent) {
    e.preventDefault();

    const ref = this.dialog.open<
      RejectProposalDialogComponent,
      null,
      { rejected?: boolean } | undefined
    >(RejectProposalDialogComponent, {
      width: "500px",
      maxWidth: "90%"
    });

    ref.afterClosed().subscribe((results) => {
      if (results?.rejected) {
        this.pagingService.navigateToProposalRejected();
      }
    });
  }
}
