import { Component, OnDestroy, OnInit } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { ServiceLinkPagingService } from "src/app/module-imports/service-link/services/service-link-paging.service";
import { ServiceLinkStoreService } from "src/app/module-imports/service-link/services/service-link-store.service";
import { NgIf, AsyncPipe } from "@angular/common";
import { ButtonComponent } from "../../inputs/button/button.component";
import { PricePipe } from "../../../pipes/sharedpipes/price.pipe";
import { FloorPipe } from "../../../pipes/sharedpipes/floor.pipe";
import { TranslateModule } from "@ngx-translate/core";

export enum ButtonState {
  Next = 1,
  Order,
  NotNow
}

@Component({
  selector: "app-footer-proposal",
  templateUrl: "./footer-service-link.component.html",
  standalone: true,
  imports: [
    NgIf,
    ButtonComponent,
    AsyncPipe,
    PricePipe,
    FloorPipe,
    TranslateModule
  ]
})
export class FooterServiceLinkComponent implements OnInit {
  anyProposalIsNull$: Observable<boolean> =
    this.serviceLinkStoreService.touchedProposals$.pipe(
      map((x) => x.length === 0 || x.some((i) => i.value === null))
    );

  isLoaded = false;
  anyTrue = false;
  buttonState: ButtonState = ButtonState.NotNow;

  anyTrue$: Observable<boolean> =
    this.serviceLinkStoreService.touchedProposals$.pipe(
      map((x) => x.some((i) => i.value === true))
    );

  constructor(
    public serviceLinkPagingService: ServiceLinkPagingService,
    public serviceLinkStoreService: ServiceLinkStoreService
  ) {}

  ngOnInit() {
    this.anyTrue$.subscribe((anyTrue) => {
      this.isLoaded = true;
      this.anyTrue = anyTrue;
      this.updateButtonState();
    });
  }

  private updateButtonState() {
    if (this.serviceLinkPagingService.isStartStep) {
      this.buttonState = ButtonState.Next;
    } else if (this.serviceLinkPagingService.isSummaryStep) {
      this.buttonState = this.anyTrue ? ButtonState.Order : ButtonState.NotNow;
    }
  }

  async nextClick() {
    await this.serviceLinkPagingService.navigateForward(this.anyTrue);
    this.updateButtonState();
  }

  async backClick() {
    await this.serviceLinkPagingService.navigateBack();
    this.updateButtonState();
  }

  disableNextbutton() {
    return (
      this.serviceLinkStoreService.isPreview ||
      this.serviceLinkStoreService.isStatusCustomerReviewed()
    );
  }

  getNextButtonText(): string {
    return this.buttonState === ButtonState.Order
      ? "service-link.footer.order"
      : this.buttonState === ButtonState.Next
        ? "service-link.footer.next"
        : "service-link.footer.answer-no";
  }
}
