import { NgFor, NgIf } from "@angular/common";
import { Component, Input } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { expandAnimation } from "src/app/animations/expand";
import { PackagePricePipe } from "src/app/pipes/package-price.pipe";
import { PriceDiffPipePipe } from "src/app/pipes/price-diff-pipe.pipe";
import { PricePipe } from "src/app/pipes/sharedpipes/price.pipe";
import { FormService } from "src/app/services/form.service";
import { PriceService } from "src/app/services/price.service";
import { PackageResponse, PriceTypeEnum } from "src/domain/client";
import { CheckboxComponent } from "../../inputs/checkbox/checkbox.component";

@Component({
  selector: "app-additional-services-checkbox",
  standalone: true,
  imports: [
    NgFor,
    NgIf,
    PricePipe,
    PriceDiffPipePipe,
    PackagePricePipe,
    CheckboxComponent
  ],
  templateUrl: "./additional-services-checkbox.component.html",
  animations: [expandAnimation]
})
export class AdditionalServicesCheckboxComponent {
  @Input() packages: PackageResponse[];
  @Input() parentForm: FormGroup<any>;

  public PriceTypeEnum: typeof PriceTypeEnum = PriceTypeEnum;

  constructor(
    public priceService: PriceService,
    public formService: FormService
  ) {}

  extraServicesParentForm(id: string): FormGroup<any> {
    return this.parentForm.get(id) as FormGroup<any>;
  }
}
