import { Component, OnInit } from "@angular/core";
import { UrlService } from "src/app/services/url.service";
import { Router, RouterLink } from "@angular/router";
import { ButtonComponent } from "../../inputs/button/button.component";
import { TranslateModule } from "@ngx-translate/core";
import { FormService } from "src/app/services/form.service";

@Component({
  selector: "app-maintenance",
  templateUrl: "./maintenance.component.html",
  standalone: true,
  imports: [RouterLink, ButtonComponent, TranslateModule]
})
export class MaintenanceComponent {
  constructor(
    private urlService: UrlService,
    private router: Router,
    private formService: FormService
  ) {}

  backToStart() {
    this.formService.aboutVehicleForm.markAsDirty();
    this.formService.aboutVehicleForm.patchValue({
      carText: ""
    });
    this.router.navigate([this.urlService.brand, "/form/1"], {
      replaceUrl: true
    });
  }
}
